import EventImg from "../images/event.jpeg";

export const events = [
  {
    id: 1,
    title: "Meet and Greet",
    image: EventImg,
    date: "August 16, 2023",
  },
];
